<template>
  <div class="nav-button">
    <span v-for="i in 4" :key="i" class="nav-button__bar"></span>
  </div>
</template>

<script>
export default {

};
</script>

<style lang="scss" scoped>
.nav-button {
  padding: 10px;
  position: relative;
  cursor: pointer;
  z-index: 999;
  border-radius: 50%;
  background-color: #607D8A;
  width: 150px;
  height: 150px;
  top: -80px;
  left: -80px;
  z-index: 2;

  @media (min-width: 600px) {
    display: none;
  }

  &__bar {
    display: block;
    position: absolute;
    background-color: white;
    width: 20px;
    height: 2px;

    @for $i from 0 through 4 {
      &:nth-child(#{$i}) {
        bottom: #{50 - $i * 5}px;
        right: 30px;
      }
    }
  }
}
</style>
